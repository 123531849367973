<template>
  <div>
    <el-dialog
      :title="titleDialog"
      :visible="showDialog"
      @close="close"
      @open="create"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      width="65%"
    >
      <form autocomplete="off" @submit.prevent="submit">
        <v-row>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <div
              class="form-group"
              :class="{ 'error--text': errors.category_id }"
            >
              <label class="control-label"> Categoría</label>
              <el-select v-model="form.category_id" filterable>
                <el-option
                  v-for="option in categories"
                  :key="option.id"
                  :value="option.id"
                  :label="option.name"
                ></el-option>
              </el-select>
              <small
                class="error--text"
                v-if="errors.category_id"
                v-text="errors.category_id[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6" sm="6">
            <div
              class="form-group"
              :class="{ 'error--text': errors.command_zone_id }"
            >
              <label class="control-label">Tipo</label>
              <el-select
                v-model="form.item_type_id"
                dusk="item_type_id"
                @change="changeItemType"
              >
                <el-option
                  v-for="option in itemTypes"
                  :key="option.id"
                  :value="option.id"
                  :label="option.description"
                ></el-option>
              </el-select>
              <small
                class="form-control-feedback"
                v-if="errors.item_type_id"
                v-text="errors.item_type_id[0]"
              ></small>
              <small
                class="error--text"
                v-if="errors.command_zone_id"
                v-text="errors.command_zone_id[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="6" lg="12" md="12" sm="12">
            <div class="form-group" :class="{ 'error--text': errors.name }">
              <label class="control-label">Nombre</label>
              <el-input v-model="form.name" dusk="name"></el-input>
              <small
                class="error--text"
                v-if="errors.name"
                v-text="errors.name[0]"
              ></small>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="8" lg="8" md="8" sm="12">
            <div
              class="form-group"
              :class="{ 'error--text': errors.description }"
            >
              <label class="control-label">Descripción</label>
              <el-input
                v-model="form.description"
                dusk="description"
              ></el-input>
              <small
                class="error--text"
                v-if="errors.description"
                v-text="errors.description[0]"
              ></small>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            v-if="form.presentation"
          >
            <div class="form-group">
              <label class="control-label">Precio unitario</label>
              <el-input-number
                v-model="form.presentation.sale_price"
                dusk="sale_price"
                controls-position="right"
                :min="0"
                :step="0.1"
                :precision="2"
              ></el-input-number>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="4" lg="6" md="6" sm="6">
            <div
              class="form-group"
              :class="{ 'error--text': errors.internal_id }"
            >
              <label class="control-label"
                >Código Interno
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Código interno de la empresa para el control de sus productos"
                  placement="top-start"
                >
                  <i class="fa fa-info-circle"></i>
                </el-tooltip>
              </label>
              <el-input
                v-model="form.internal_id"
                dusk="internal_id"
              ></el-input>
              <small
                class="error--text"
                v-if="errors.internal_id"
                v-text="errors.internal_id[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="6" md="6" sm="6">
            <div
              class="form-group"
              :class="{ 'error--text': errors.item_code }"
            >
              <label class="control-label"
                >Código Sunat
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Código proporcionado por SUNAT, campo obligatorio para exportaciones"
                  placement="top-start"
                >
                  <i class="fa fa-info-circle"></i>
                </el-tooltip>
              </label>
              <el-input v-model="form.item_code" dusk="item_code"></el-input>
              <small
                class="error--text"
                v-if="errors.item_code"
                v-text="errors.item_code[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="4" lg="12" md="12" sm="12">
            <div
              class="form-group"
              :class="{ 'error--text': errors.unit_type_id }"
            >
              <label class="control-label">Unidad</label>
              <el-select
                v-model="form.unit_type_id"
                dusk="unit_type_id"
                filterable
              >
                <el-option
                  v-for="option in unitTypes"
                  :key="option.id"
                  :value="option.id"
                  :label="option.description"
                ></el-option>
              </el-select>
              <small
                class="error--text"
                v-if="errors.unit_type_id"
                v-text="errors.unit_type_id[0]"
              ></small>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="3" lg="3" md="3" sm="3">
            <div
              class="form-group"
              :class="{ 'error--text': errors.sale_affectation_igv_type_id }"
            >
              <label class="control-label">Tipo de afectación (Venta)</label>
              <el-select
                v-model="form.sale_affectation_igv_type_id"
                @change="changeAffectationIgvType"
              >
                <el-option
                  v-for="option in affectationIgvTypes"
                  :key="option.id"
                  :value="option.id"
                  :label="option.description"
                ></el-option>
              </el-select>
              <small
                class="error--text"
                v-if="errors.sale_affectation_igv_type_id"
                v-text="errors.sale_affectation_igv_type_id[0]"
              ></small>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            v-if="form.item_type_id == '01'"
          >
            <label>Control de stock</label>
            <div class="form-group">
                <el-select
                    v-model="form.stock_control"
                >
                    <el-option
                    :key="0"
                    :value="false"
                    label="No"
                    ></el-option>
                    <el-option
                    :key="1"
                    :value="true"
                    label="Si"
                    ></el-option>
                </el-select>
              <!-- <el-switch
                v-model="form.stock_control"
                active-text="Si"
                inactive-text="No"
              ></el-switch> -->
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="3"
            v-if="form.stock_control && !form.id && form.item_type_id == '01'"
          >
            <div class="form-group" :class="{ 'error--text': errors.stock }">
              <label class="control-label">Stock inicial</label>
              <el-input v-model="form.stock" dusk="stock"></el-input>
              <small
                class="error--text"
                v-if="errors.stock"
                v-text="errors.stock[0]"
              ></small>
            </div>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="3" v-show="show_has_igv">
            <label>Incluye IGV</label>
            <div class="form-group">
              <!-- <el-checkbox v-model="form.has_igv">Incluye Igv</el-checkbox> -->
              <el-select
                    v-model="form.has_igv"
                >
                    <el-option
                    :key="2"
                    :value="false"
                    label="No"
                    ></el-option>
                    <el-option
                    :key="3"
                    :value="true"
                    label="Si"
                    ></el-option>
                </el-select>
              <!-- <el-switch
                v-model="form.has_igv"
                active-text="Si"
                inactive-text="No"
              ></el-switch> -->
            </div>
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="3" sm="3">
            <label>Es producto de limpieza</label>
            <div class="form-group">
              <!-- <el-checkbox v-model="form.has_igv">Incluye Igv</el-checkbox> -->
              <el-select
                    v-model="form.is_cleaning_product"
                >
                    <el-option
                    :key="2"
                    :value="false"
                    label="No"
                    ></el-option>
                    <el-option
                    :key="3"
                    :value="true"
                    label="Si"
                    ></el-option>
                </el-select>
              <!-- <el-switch
                v-model="form.has_igv"
                active-text="Si"
                inactive-text="No"
              ></el-switch> -->
            </div>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" xl="12" lg="12" md="12" sm="12">
            <el-button type="danger" plain @click.prevent="close()"
              >Cancelar</el-button
            >
            <el-button type="success" @click.prevent="showDialogNewCategory = true"
              >Nueva categoría</el-button
            >
            <el-button
              type="primary"
              native-type="submit"
              :loading="loading_submit"
            >
              <template v-if="loading_submit">
                Guardando...
              </template>
              <template v-else>
                Guardar
              </template>
            </el-button>
          </v-col>
        </v-row>
      </form>

      <category-form
        :showDialog.sync="showDialogNewCategory"
        :external="true"
      ></category-form>
    </el-dialog>
  </div>
</template>

<script>
import CategoryForm from "Views/Admin/Categories/Form.vue";
import { deletable } from "Mixins/deletable";
import { confirm } from "Mixins/confirm";
import dayjs from "dayjs";

export default {
  props: ["showDialog", "recordId", "external"],
  components: { CategoryForm },
  mixins: [deletable, confirm],
  data() {
    return {
      loading: false,
      loading_submit: false,
      showDialogNewCategory: false,
      titleDialog: null,
      resource: "items",
      errors: {},
      form: {},
      categories: [],
      itemTypes: [],
      unitTypes: [],
      currencyTypes: [],
      systemIscTypes: [],
      affectationIgvTypes: [],
      establishment_id: {},
      ingredients: [],
      warehouses: [],
      items: [],
      disabledChecks: false,
      show_has_igv: true,
      pickerOptions: {
        disabledDate: (time) => {
          time = dayjs(time).format("YYYY-MM-DD");
          return dayjs().format("YYYY-MM-DD") > time;
        },
      },
    };
  },
  created() {
    this.$eventHub.$on("reloadDataCategories", (category_id) => {
      this.reloadDataCategories(category_id);
    });
  },

  methods: {
    initForm() {
      (this.loading_submit = false), (this.errors = {});
      this.form = {
        id: null,
        category_id: null,
        name: null,
        description: null,
        item_type_id: "01",
        internal_id: null,
        item_code: null,
        unit_type_id: "NIU",
        currency_type_id: "PEN",
        has_igv: true,
        has_isc: false,
        system_isc_type_id: null,
        percentage_isc: 0,
        sale_affectation_igv_type_id: "20",
        percentage_perception: null,
        stock_control: false,
        warehouse_id: null,
        is_cleaning_product: false,
        stock: 0,
        presentation: {
          id: null,
          establishment_id: null,
          name: null,
          sale_price: 0,
        },
      };
    },
    getStaticTables() {
      this.$http.get(`/${this.resource}/static-tables`).then((response) => {
        this.currencyTypes = response.data.currencyTypes;
        this.systemIscTypes = response.data.systemIscTypes;
        this.affectationIgvTypes = response.data.affectationIgvTypes;
        this.itemTypes = response.data.itemTypes;

        // this.form.sale_affectation_igv_type_id =
        // 	this.affectationIgvTypes.length > 0 ? this.affectationIgvTypes[0].id : null;
      });
    },
    getDynamicTables() {
      this.$http.get(`/${this.resource}/dynamic-tables`).then((response) => {
        this.categories = response.data.categories;
        this.unitTypes = response.data.unitTypes;
        this.warehouses = response.data.warehouses;
        this.form.presentation.establishment_id =
          response.data.establishment_id;

        this.form.warehouse_id =
          this.warehouses.length > 0 ? this.warehouses[0].id : null;

        if (this.recordId) {
          this.$http
            .get(`/${this.resource}/record/${this.recordId}`)
            .then((response) => {
              this.form = response.data.data;

              this.changeItemType();
              this.changeAffectationIgvType();
            });
        }

        this.loading = true;
      });
    },
    changeAffectationIgvType() {
      let affectation_igv_type_exonerated = [
        20,
        21,
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
      ];
      let is_exonerated = affectation_igv_type_exonerated.includes(
        parseInt(this.form.sale_affectation_igv_type_id)
      );

      if (is_exonerated) {
        this.show_has_igv = false;
        this.form.has_igv = true;
      } else {
        this.show_has_igv = true;
      }
    },
    changeItemType() {
      if (this.form.item_type_id == "01") {
        this.titleDialog = this.recordId ? "Editar producto" : "Nuevo producto";
        this.form.unit_type_id = "NIU";
      }
      if (this.form.item_type_id == "02") {
        this.titleDialog = this.recordId ? "Editar servicio" : "Nuevo servicio";
        this.form.unit_type_id = "ZZ";
      }
    },
    async create() {
      // this.titleDialog = this.recordId ? 'Editar producto' : 'Nuevo producto';
      await this.initForm();
      await this.getStaticTables();
      await this.getDynamicTables();
    },
    resetForm() {
      this.initForm();
      this.form.sale_affectation_igv_type_id =
        this.affectationIgvTypes.length > 0
          ? this.affectationIgvTypes[0].id
          : null;
    },
    async submit() {
      this.confirm().then(() => {
        this.loading_submit = true;
        this.$http
          .post(`/${this.resource}`, this.form)
          .then((response) => {
            if (response.data.success) {
              this.$message.success(response.data.message);
              if (this.external) {
                this.$eventHub.$emit("reloadDataItems", response.data.id);
              } else {
                this.$eventHub.$emit("reloadData", this.resource);
              }
              this.close();
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            } else {
              this.$message.error(error.response.data.message);
            }
          })
          .then(() => {
            this.loading_submit = false;
          });
      });
    },
    close() {
      this.$emit("update:showDialog", false);
      this.resetForm();
    },
    reloadDataCategories(category_id) {
      this.$http.get(`/${this.resource}/table/categories`).then((response) => {
        this.categories = response.data;
        this.form.category_id = category_id;
      });
    },
  },
};
</script>
